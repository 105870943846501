import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import './Documents.css';

import pdfjsWorker from 'pdfjs-dist/webpack'; // Import the webpack version
import 'pdfjs-dist/web/pdf_viewer.css'; // Import the CSS

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error);
  };

  useEffect(() => {
    const updateWidth = () => {
      // Calculate the maximum width, e.g., 800px or the window width if smaller
      const maxWidth = Math.min(window.innerWidth, 800);
      setPageWidth(maxWidth);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    // Fetch the PDF file and check if it exists
    async function checkPdfFile() {
      try {
        const response = await fetch('/WHITEPAPER.pdf'); // Adjust the path as needed
        if (response.ok) {
          console.log('PDF file found.'); // Output a message if the file exists
        } else {
          console.error('PDF file not found.'); // Output an error message if the file is not found
        }
      } catch (error) {
        console.error('Error fetching PDF file:', error); // Output an error if there's an issue with the request
      }
    }

    checkPdfFile();
  }, []);

  return (
    <div className="centered-container">
      <div className="menu">
        {/* Your menu or header content goes here */}
      </div>
      <div className="pdf-container" style={{ marginTop: '20px' }}>
        {/* Add a top margin to push down the PDF content */}
        <Document
          file="/WHITEPAPER.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError} // Handle PDF loading errors
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pageWidth} />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PdfViewer;