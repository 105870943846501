import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import abi from './SmartChocolateABI.json';
import './DettaglioCioccolata.css';
import {useParams} from 'react-router-dom'; // Import useParams

let web3;
let contract;

function DettaglioCioccolata() {
    const {orderId} = useParams(); // Extract orderId from URL parameters
    const [cioccolataDetail, setChocolateDetail] = useState(null);

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/9045561d5a84419d95398b0f5e4dade5`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = '998db427affe92975df26554215fc288c3d8ce341847a61c13c5952e74dc2aaf'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0xa72e2B0BA3F74c32BcEf0c0B6f5A3f43d706D233';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchChocolateDetail = async () => {
            try {
                await initWeb3();
                if (orderId) {
                    const cioccolata = await contract.methods.getChocolateByOrderId(orderId).call();
                    setChocolateDetail(cioccolata);
                }
            } catch (error) {
                console.error('Error fetching cioccolata detail:', error);
            }
        };

        fetchChocolateDetail();
    }, [orderId]); // Add orderId as a dependency for useEffect

    return (
        <div className="container">
            <div className="divStyle">
                <h1>Dettagli Cioccolata</h1>
                {cioccolataDetail ? (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th" colSpan="6" style={{textAlign: 'center'}}>Miscelazione</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Concaggio</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Temperaggio</th>
                            <th className="th" style={{textAlign: 'center'}}>Modellaggio</th>
                        </tr>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Cacao</th>
                            <th className="th" style={{textAlign: 'center'}}>Cacao %</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro di Cacao</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro di Cacao %</th>
                            <th className="th" style={{textAlign: 'center'}}>Zucchero</th>
                            <th className="th" style={{textAlign: 'center'}}>Zucchero %</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Concaggio</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Raffreddamento</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Riscaldamento</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Modellaggio</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td">{cioccolataDetail.ingredients[0]} </td>
                            <td className="td">{cioccolataDetail.cacaoPercent} %</td>
                            <td className="td">{cioccolataDetail.ingredients[1]} </td>
                            <td className="td">{cioccolataDetail.burroCacaoPercent} %</td>
                            <td className="td">{cioccolataDetail.ingredients[2]} </td>
                            <td className="td">{cioccolataDetail.zuccheroPercent} %</td>
                            <td className="td">{cioccolataDetail.temperatura1} </td>
                            <td className="td">{cioccolataDetail.tempo} </td>
                            <td className="td">{cioccolataDetail.temperatura2} </td>
                            <td className="td">{cioccolataDetail.temperatura3} </td>
                            <td className="td">{cioccolataDetail.temperatura4} </td>
                        </tr>
                        </tbody>
                    </table>
                ) : (
                    <p>Caricamento in corso...</p>
                )}
            </div>
            {/* Aggiungi il div per le spiegazioni sugli ingredienti */}
            <div className="ingredientsExplanation">
                <h2>Origine degli Ingredienti</h2>
                <p>
                    La nostra cioccolateria si impegna a utilizzare ingredienti di alta qualità provenienti da fonti
                    affidabili. Ecco un breve riassunto dell'origine degli ingredienti principali:
                </p>
                <ul>
                    <li><strong>Cacao:</strong> Il nostro cacao pregiato proviene da coltivazioni selezionate, offrendo
                        un profilo aromatico ricco e un gusto distintivo al nostro cioccolato.
                    </li>
                    <li><strong>Zucchero:</strong> Lo zucchero utilizzato nel processo di produzione contribuisce a
                        bilanciare dolcezza e amarezza, garantendo un'esperienza di gusto equilibrata nel nostro
                        cioccolato.
                    </li>
                </ul>
                <p>
                    Siamo orgogliosi di offrire ai nostri clienti una cioccolata di alta qualità con ingredienti
                    selezionati con cura e provenienti da fonti affidabili.
                </p>
            </div>
        </div>
    );
}

export default DettaglioCioccolata;
