import React, {useState, useEffect, useRef} from 'react';
import Web3 from 'web3';
import abi from './SmartChocolateABI.json';

let web3;
let contract;

function AggiungiCioccolata() {
    const [cioccolataInfo, setCioccolataInfo] = useState({ /* ... come prima ... */});
    const [metamaskDetected, setMetamaskDetected] = useState(true);
    const [selectedPhase, setSelectedPhase] = useState('MISCELAZIONE'); // Stato iniziale
    const ownerAddress = '0x0c6Ed9de43C6a0F0918E267A8eba352Ab5624f28'; // Indirizzo del proprietario
    const [isOwner, setIsOwner] = useState(false);


    const audioRef = useRef();

    useEffect(() => {
        const initWeb3 = async () => {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({method: 'eth_requestAccounts'});
                    web3 = new Web3(window.ethereum);

                    const accounts = await web3.eth.getAccounts();
                    console.log('Indirizzo dell\'utente connesso:', accounts[0]);
                    if (accounts[0].toLowerCase() === ownerAddress.toLowerCase()) {
                        // L'utente connesso è il proprietario
                        setIsOwner(true);
                    } else {
                        setIsOwner(false);
                    }
                    console.log("Test", setIsOwner)
                } catch (error) {
                    console.error('User denied account access');
                }
            } else if (window.web3 && window.web3.currentProvider) {
                web3 = new Web3(window.web3.currentProvider);
            } else {
                console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
                setMetamaskDetected(false);
                return;
            }

            const address = '0xa72e2B0BA3F74c32BcEf0c0B6f5A3f43d706D233';
            if (web3) {
                contract = new web3.eth.Contract(abi, address);
            }
        };

        initWeb3();
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setCioccolataInfo({
            ...cioccolataInfo,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const accounts = await web3.eth.getAccounts();
            const cioccolataInput = {
                orderId: parseInt(cioccolataInfo.orderId, 10),
                ingredients: [cioccolataInfo.cacao, cioccolataInfo.burroCacao, cioccolataInfo.zucchero],
                cacaoPercent: parseInt(cioccolataInfo.cacaoPercent, 10),
                burroCacaoPercent: parseInt(cioccolataInfo.burroCacaoPercent, 10),
                zuccheroPercent: parseInt(cioccolataInfo.zuccheroPercent, 10),
                temperatura1: parseInt(cioccolataInfo.temperatura1, 10),
                tempo: parseInt(cioccolataInfo.tempo, 10),
                temperatura2: parseInt(cioccolataInfo.temperatura2, 10),
                temperatura3: parseInt(cioccolataInfo.temperatura3, 10),
                temperatura4: parseInt(cioccolataInfo.temperatura4, 10),
            };

            console.log('cioccolataInput:', cioccolataInput);

            const gasPrice = await web3.eth.getGasPrice();

            await contract.methods.addChocolate(
                cioccolataInput.orderId,
                cioccolataInput.ingredients,
                cioccolataInput.cacaoPercent,
                cioccolataInput.burroCacaoPercent,
                cioccolataInput.zuccheroPercent,
                cioccolataInput.temperatura1,
                cioccolataInput.tempo,
                cioccolataInput.temperatura2,
                cioccolataInput.temperatura3,
                cioccolataInput.temperatura4,
            ).send({
                from: accounts[0],
                gas: 2100000,
                gasPrice: gasPrice
            });

            console.log('Dati della cioccolata inviati:', cioccolataInfo);

            setCioccolataInfo({
                orderId: '',
                cacao: '',
                burroCacao: '',
                zucchero: '',
                cacaoPercent: '',
                burroCacaoPercent: '',
                zuccheroPercent: '',
                temperatura1: '',
                tempo: '',
                temperatura2: '',
                temperatura3: '',
                temperatura4: '',
            });
            audioRef.current.play();
        } catch (error) {
            console.error('Errore nell’invio dei dati:', error.message);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            {(!isOwner) && (
                <div
                    style={{
                        backgroundColor: 'rgba(205, 149, 116, 0.6)',
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '10px', // Riduci lo spazio superiore
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '600px',
                        margin: '10px auto',
                    }}
                >
                    ATTENZIONE: Solo il proprietario per utilizzare questa funzione!
                </div>
            )}
            <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
                <img
                    src="/robot1.png"
                    alt="Robot"
                    style={{
                        width: '250px',
                        height: '250px',
                        position: 'absolute',
                        bottom: '15px', // Set this to align the image to the bottom
                        right: '650px',
                        zIndex: 1
                    }}
                />
                <img
                    src="/robot2.png"
                    alt="Robot 2"
                    style={{
                        width: '100px',
                        height: '100px',
                        position: 'absolute',
                        top: '20px',
                        right: '80px',
                        zIndex: 1
                    }}
                />
                <div
                    style={{
                        backgroundColor: 'rgba(205, 149, 116, 0.6)',
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '620px',
                        margin: '20px',
                        marginLeft: 'auto',
                        marginRight: '100px',
                        textAlign: 'center',
                        marginTop: '60px',
                        height: '580px', // Imposta un'altezza fissa desiderata
                        overflow: 'auto', // Aggiunge una barra di scorrimento se il contenuto supera l'altezza
                        position: 'relative', // Imposta la posizione relativa
                        width: "600px",
                    }}
                >
                    <img
                        src="/logo.png"
                        alt="SmartChoco Logo"
                        style={{
                            marginBottom: '5px',
                            maxWidth: '200px',
                        }}
                    />

                    <div>
                        <div className="mb-2" style={{display: 'flex', alignItems: 'center', marginLeft: '10%'}}>
                            <label htmlFor="orderId" className="form-label" style={{color: 'white', width: '180px'}}>Order
                                ID</label>
                            <input
                                type="number"
                                className="form-control"
                                id="orderId"
                                name="orderId"
                                value={cioccolataInfo.orderId}
                                onChange={handleInputChange}
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                    border: '1px solid white',
                                    width: '80px'
                                }}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'MISCELAZIONE' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('MISCELAZIONE')}
                        >
                            MISCELAZIONE
                            {selectedPhase === 'MISCELAZIONE' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'CONCAGGIO' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('CONCAGGIO')}
                        >
                            CONCAGGIO
                            {selectedPhase === 'CONCAGGIO' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'TEMPERAGGIO' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('TEMPERAGGIO')}
                        >
                            TEMPERAGGIO
                            {selectedPhase === 'TEMPERAGGIO' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'MODELLAGGIO' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('MODELLAGGIO')}
                        >
                            MODELLAGGIO
                            {selectedPhase === 'MODELLAGGIO' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                    </div>


                    <br></br>
                    {/* FASE MISCELAZIONE */}
                    {selectedPhase === 'MISCELAZIONE' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="cacao" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Cacao</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cacao"
                                    name="cacao"
                                    value={cioccolataInfo.cacao}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="cacaoPercent" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Percentuale</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="cacaoPercent"
                                    name="cacaoPercent"
                                    value={cioccolataInfo.cacaoPercent}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="burroCacao" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Burro di Cacao</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="burroCacao"
                                    name="burroCacao"
                                    value={cioccolataInfo.burroCacao}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="burroCacaoPercent" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Percentuale</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="burroCacaoPercent"
                                    name="burroCacaoPercent"
                                    value={cioccolataInfo.burroCacaoPercent}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="zucchero" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Zucchero</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="zucchero"
                                    name="zucchero"
                                    value={cioccolataInfo.zucchero}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="zuccheroPercent" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Percentuale</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="zuccheroPercent"
                                    name="zuccheroPercent"
                                    value={cioccolataInfo.zuccheroPercent}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE CONCAGGIO */}
                    {selectedPhase === 'CONCAGGIO' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura1"
                                    name="temperatura1"
                                    value={cioccolataInfo.temperatura1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tempo" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Tempo</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tempo"
                                    name="tempo"
                                    value={cioccolataInfo.tempo}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE TEMPERAGGIO */}
                    {selectedPhase === 'TEMPERAGGIO' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura Raffreddamento</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura2"
                                    name="temperatura2"
                                    value={cioccolataInfo.temperatura2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura3" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura Riscaldamento</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura3"
                                    name="temperatura3"
                                    value={cioccolataInfo.temperatura3}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE MODELLAGGIO */}
                    {selectedPhase === 'MODELLAGGIO' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura4" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura4"
                                    name="temperatura4"
                                    value={cioccolataInfo.temperatura4}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}


                    <hr
                        style={{
                            borderColor: 'white',
                            borderStyle: 'dashed',
                            borderWidth: '2px',
                            position: 'absolute', // Imposta la posizione assoluta
                            bottom: '55px', // Posiziona l'elemento 30px sopra il bottone "Aggiungi Choco"
                            left: '50%', // Centra l'elemento orizzontalmente
                            transform: 'translateX(-50%)', // Centra l'elemento orizzontalmente
                            width: '100%', // Imposta la larghezza al 100% per coprire tutto il contenitore
                        }}
                    />

                    <button type="submit" className="btn btn-light" style={{
                        color: 'black',
                        marginTop: '10px',
                        position: 'absolute', // Imposta la posizione assoluta
                        bottom: '20px', // Posiziona il bottone in fondo al div
                        left: '50%', // Centra il bottone orizzontalmente
                        transform: 'translateX(-50%)', // Centra il bottone orizzontalmente
                    }}>
                        Aggiungi Cioccolata
                    </button>
                </div>
                <audio ref={audioRef} src="/sound.mp3" preload="auto"></audio>
            </div>
        </form>
    );
}

export default AggiungiCioccolata;
