import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import QRCode from 'qrcode.react';
import abi from './SmartChocolateABI.json';
import './ListaCioccolata.css';
import {Link} from 'react-router-dom';

let web3;
let contract;

function ListaCioccolata() {
    const [cioccolate, setCioccolate] = useState([]);
    const [cioccolatoDetail, setCioccolatoDetail] = useState(null);
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/9045561d5a84419d95398b0f5e4dade5`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = '998db427affe92975df26554215fc288c3d8ce341847a61c13c5952e74dc2aaf'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0xa72e2B0BA3F74c32BcEf0c0B6f5A3f43d706D233';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchChocolates = async () => {
            try {
                await initWeb3();
                const cioccolate = await contract.methods.getAllChocolates().call();
                setCioccolate(cioccolate);
            } catch (error) {
                console.error('Error fetching cioccolate:', error);
            }
        };

        fetchChocolates();
    }, []);

    const fetchChocoDetail = async (orderId) => {
        try {
            const cioccolato = await contract.methods.getChocolateByOrderId(orderId).call();
            setCioccolatoDetail(cioccolato);
        } catch (error) {
            console.error('Error fetching cioccolato detail:', error);
        }
    };

    return (
        <div className="container">
            <div className="divStyle">
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Ricerca Cioccolato</h1>
                <input
                    type="text"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    placeholder="Inserisci ID dell'ordine"
                    className="inputStyle"
                />
                <button onClick={() => fetchChocoDetail(orderId)} className="buttonStyle">Ricerca</button>
                {cioccolatoDetail && (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th" colSpan="6" style={{textAlign: 'center'}}>Miscelazione</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Concaggio</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Temperaggio</th>
                            <th className="th" style={{textAlign: 'center'}}>Modellaggio</th>
                            <th className="th" style={{textAlign: 'center'}}></th>
                        </tr>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Cacao</th>
                            <th className="th" style={{textAlign: 'center'}}>Cacao %</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro di Cacao</th>
                            <th className="th" style={{textAlign: 'center'}}>Burro di Cacao %</th>
                            <th className="th" style={{textAlign: 'center'}}>Zucchero</th>
                            <th className="th" style={{textAlign: 'center'}}>Zucchero %</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Concaggio</th>
                            <th className="th" style={{textAlign: 'center'}}>Tempo</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Raffreddamento</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Riscaldamento</th>
                            <th className="th" style={{textAlign: 'center'}}>Temperatura Modellaggio</th>
                            <th className="th" style={{textAlign: 'center'}}>QR Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td">{cioccolatoDetail.ingredients[0]} </td>
                            <td className="td">{cioccolatoDetail.cacaoPercent} %</td>
                            <td className="td">{cioccolatoDetail.ingredients[1]} </td>
                            <td className="td">{cioccolatoDetail.burroCacaoPercent} %</td>
                            <td className="td">{cioccolatoDetail.ingredients[2]} </td>
                            <td className="td">{cioccolatoDetail.zuccheroPercent} %</td>
                            <td className="td">{cioccolatoDetail.temperatura1} </td>
                            <td className="td">{cioccolatoDetail.tempo} </td>
                            <td className="td">{cioccolatoDetail.temperatura2} </td>
                            <td className="td">{cioccolatoDetail.temperatura3} </td>
                            <td className="td">{cioccolatoDetail.temperatura4} </td>
                            <td className="td">
                                <Link to={`/dettaglio-cioccolata/${cioccolatoDetail.orderId}`}>
                                    <QRCode
                                        value={`${window.location.origin}/dettaglio-cioccolata/${cioccolatoDetail.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </div>
            <div className="divStyle">
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Tutto il Cioccolato</h1>
                <table className="tableStyle">
                    <thead>
                    <tr>
                        <th className="th"></th>
                        <th className="th" colSpan="6" style={{textAlign: 'center'}}>Miscelazione</th>
                        <th className="th" colSpan="2" style={{textAlign: 'center'}}>Concaggio</th>
                        <th className="th" colSpan="2" style={{textAlign: 'center'}}>Temperaggio</th>
                        <th className="th" style={{textAlign: 'center'}}>Modellaggio</th>
                        <th className="th" style={{textAlign: 'center'}}></th>
                    </tr>
                    <tr>
                        <th className="th" style={{textAlign: 'center'}}>Numero Ordine</th>
                        <th className="th" style={{textAlign: 'center'}}>Cacao</th>
                        <th className="th" style={{textAlign: 'center'}}>Cacao %</th>
                        <th className="th" style={{textAlign: 'center'}}>Burro di Cacao</th>
                        <th className="th" style={{textAlign: 'center'}}>Burro di Cacao %</th>
                        <th className="th" style={{textAlign: 'center'}}>Zucchero</th>
                        <th className="th" style={{textAlign: 'center'}}>Zucchero %</th>
                        <th className="th" style={{textAlign: 'center'}}>Temperatura Concaggio</th>
                        <th className="th" style={{textAlign: 'center'}}>Tempo</th>
                        <th className="th" style={{textAlign: 'center'}}>Temperatura Raffreddamento</th>
                        <th className="th" style={{textAlign: 'center'}}>Temperatura Riscaldamento</th>
                        <th className="th" style={{textAlign: 'center'}}>Temperatura Modellaggio</th>
                        <th className="th" style={{textAlign: 'center'}}>QR Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cioccolate.map((cioccolato, index) => (
                        <tr key={cioccolato.orderId}>
                            <td className="td">{index + 1}</td>
                            <td className="td">{cioccolato.ingredients[0]} </td>
                            <td className="td">{cioccolato.cacaoPercent} %</td>
                            <td className="td">{cioccolato.ingredients[1]} </td>
                            <td className="td">{cioccolato.burroCacaoPercent} %</td>
                            <td className="td">{cioccolato.ingredients[2]} </td>
                            <td className="td">{cioccolato.zuccheroPercent} %</td>
                            <td className="td">{cioccolato.temperatura1} </td>
                            <td className="td">{cioccolato.tempo} </td>
                            <td className="td">{cioccolato.temperatura2} </td>
                            <td className="td">{cioccolato.temperatura3} </td>
                            <td className="td">{cioccolato.temperatura4} </td>
                            <td className="td">
                                <Link to={`/dettaglio-cioccolata/${cioccolato.orderId}`}>
                                    <QRCode
                                        value={`${window.location.origin}/dettaglio-cioccolata/${cioccolato.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListaCioccolata;
