import React, { useState } from 'react'; // Importa useState da React
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CustomNavbar from './Navbar';
import Home from './Home';
import Partner from './Partner';
import AggiungiCioccolata from './AggiungiCioccolata'; // Importa il componente AggiungiChoco
import ListaCioccolata from './ListaCioccolata'; // Importa il componente ListaPizze
import Document from './Documents'; // Importa il componente Document
import DettaglioCioccolata from './DettaglioCioccolata'; // Importa il componente DettaglioChoco

function AppRouter() {
  const [cioccolataDetail, setChocolateDetail] = useState(null); // Inizializza chocoDetail utilizzando useState

  return (
    <Router>
      <CustomNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/aggiungi-cioccolata" element={<AggiungiCioccolata />} /> {/* Aggiungi la route per AggiungiChoco */}
        <Route path="/lista-cioccolata" element={<ListaCioccolata />} /> {/* Aggiungi la route per ListaChoco */}
        <Route path="/dettaglio-cioccolata/:orderId" element={<DettaglioCioccolata />} />
        <Route path="/whitepaper" element={<Document />} /> {/* Add this line */}

      </Routes>
    </Router>
  );
}

export default AppRouter;
