  import React from 'react';
  import { Link } from 'react-router-dom';
  import './Home.css'; // Importa il file CSS esterno

  function Home() {
    return (
      <div className="home-container">
        <div className="robot-image">
          <img src="/robothome.png" alt="Robothome" className="robot-image" />
        </div>

        <div className="content-container">
          <img src="/logo.png" alt="SmartChoco Logo" className="logo" />

          <div className="new-choco-text">NEW CIOCCOLATA</div>

          <Link to="/aggiungi-cioccolata">
            <button className="click-button">CLICK HERE</button>
          </Link>
        </div>
      </div>
    );
  }

  export default Home;
